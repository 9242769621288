import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import gsap from 'gsap'
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image"
import SimpleDialog from "../components/Dialog"
import "../styles/pages/video.scss"

const VideoPage = ({ transitionStatus}) => {
  React.useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.to('.video-wrap', {
        autoAlpha: 1,
        duration: 1, //if we are entering the page, let's make the div with class .video visible in one second
      });
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.video-wrap', { autoAlpha: 0, duration: 1 });//if we are exiting  the page, let's make the div with class .video transparent in one second
    }
  }, [transitionStatus]);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('false');

  const handleClickOpen = (e) => {
    const href = e.currentTarget.dataset.href;
    // console.log(e.currentTarget)
    setSelectedValue(href);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    
  };

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

  };

  const thumbWidth = 1000;
  const thumbHeight = 590;

  const videos = [
    {
      href: 'https://www.youtube.com/embed/XnCrpym_14g?enablejsapi=1',
      title: 'The Fawn',
      director: "Jacob Graham",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        
        objectFit={`cover`}
        src={
          "../images/video-thumbnails/the-fawn.jpeg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube.com/embed/3FWPrQ8m5c8?enablejsapi=1',
      title: 'The Glare',
      director: "Ryan Hover",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        
        objectFit={`cover`}
        src={
          "../images/video-thumbnails/the-glare.jpg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube.com/embed/6K2mVi2JFK4?enablejsapi=1',
      title: 'Sunray Venus',
      director: "Matthew Maddox",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        
        objectFit={`cover`}
        src={
          "../images/video-thumbnails/sunray.jpeg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube.com/embed/ZLyAtDBFCjI?enablejsapi=1',
      title: 'Arm of Golden Flame',
      director: "Jacob Graham",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        
        objectFit={`cover`}
        src={
          "../images/golden-flame.jpeg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube.com/embed/uW8BhaHStUA?enablejsapi=1',
      title: 'Outer Century',
      director: "Jacob Graham",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        
        objectFit={`cover`}
        src={
          "../images/photo-page/f.jpg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube-nocookie.com/embed/8FkmxGRfkUM?enablejsapi=1',

      title: 'Gemini Scenic',
      director: "C.B. Stockfleth",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        objectFit={`cover`}
        src={
          "../images/gemini.jpeg"
        }
        formats={["auto"]}
      />
    },
    {
      href: 'https://www.youtube-nocookie.com/embed/Iffs7-6AwA0?enablejsapi=1',

      title: 'Humaniora',
      director: "Chris Osborn",
      thumb: <StaticImage
        alt=""
        className=''
        width={thumbWidth}
        height={thumbHeight}
        objectFit={`cover`}
        src={
          "../images/humaniora.png"
        }
        formats={["auto"]}
      />
    },
    
  ]

  const videoOutput = 
    videos.map((video, index) => {
      return (
        <div className="slide" key={index}>
          <Container>
            <button className="bkg" onClick={handleClickOpen} data-href={video.href}>
              {video.thumb}
            </button>
            <div className="info">
              <div className="left">
                <Typography component="p" variant="body2Bold" color="primary.dark">
                  {video.title}
                </Typography>

              </div>
              <div className="right">
                <Typography variant="body3" color="primary.dark">
                  directed by:
                </Typography>
                <Typography  variant="body3" color="primary.dark">
                  {video.director}
                </Typography>

              </div>
            
              

            </div>
           
          
          </Container>
        </div>

      )
    })
  
  return (
    <Layout currentPage={`video`}>
      <Seo title="Video" />
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <div className="video-wrap">
        {/* <Container  >
          <Typography component="h1" variant="h1" color="primary.light">
            Video
          </Typography>
        </Container> */}
        <Slider {...settings}>
          {videoOutput}
        </Slider>

      </div>
      
     

    </Layout>
  )
} 

export default VideoPage
