import * as React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import "./style.scss"



const SimpleDialog = (props) => {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const iframe = React.useRef(null);

    React.useEffect(() => {

        if (iframe.contentWindow) {
            console.log('yoo')
            iframe.contentWindow.postMessage(
                JSON.stringify({ event: 'command', func: 'playVideo' }),
                '*'
            );

        }
    })



    return (
        <Dialog
            onClose={handleClose}
            open={open}
            maxWidth={`lg`}
            disableScrollLock={true}
            style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
            {/* <iframe
                title={`Video`}
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                width="788.54"
                height="443"
                type="text/html"
                // src="https://www.youtube.com/embed/uW8BhaHStUA?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0">
                src={selectedValue}>
            </iframe> */}
            <iframe
                width="860"
                height="515"
                src={selectedValue}
                ref={iframe}
                title={`YouTube video player ${selectedValue}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>


        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default SimpleDialog